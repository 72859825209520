<template>
  <Card v-if="vendorExtra && scopeSelectedDaterange.length > 0 && scopeQs"
        class="vendor-activity"
  >
    <template slot="title">
      <strong>{{ ellipsis(vendorExtra.name + ' ') }} </strong>{{ $t('global.vendor.activity') }}
    </template>
    <template slot="extra">
      <p style="border:none!important;margin-top:4px">
        {{ $t('global.vendor.collect_period') }} <strong>{{ scopeSelectedDaterange[0] }} - {{ scopeSelectedDaterange[1] }} </strong>
      </p>
    </template>
    <p style="margin-top:20px;display: flex;justify-content: space-between;align-items: baseline;">
      <span>{{ $t('Status') }} : {{ whichStatus }}</span>
      <Button
        v-if="!userHasRole('vendors-viewer') && !userHasRole('publisher-fp')"
        type="primary"
        style="margin:0 10px;text-align:right"
        @click="toogleEditModal"
      >
        {{ $t('global.table.qualify') }}
      </Button>
    </p>
    <SetupVendorModal
      ref="vendorRulesModal"
      @on-form-submit-success="handleFormSubmit"
    />
    <Divider />
    <List>
      <ListItem>
        <i
          class="fas fa-check-circle cmp-icon-ok"
          style="margin-right: 5px"
          v-if="vendorExtra.adstxtvendors.length > 0"
        />
        <i
          class="fas fa-times-circle cmp-icon-not-ok"
          style="margin-right: 5px"
          v-else
        />
        <span>{{ vendorExtra.adstxtvendors.length > 0 ? $t('Found in') : $t('global.filters.out') }} {{ $t('Ads.txt') }}</span>
      </ListItem>
      <ListItem>
        <i
          class="fas fa-check-circle cmp-icon-ok"
          style="margin-right: 5px"
          v-if="vendorExtra.isFoundInCmp "
        />
        <i
          class="fas fa-times-circle cmp-icon-not-ok"
          style="margin-right: 5px"
          v-else
        />
        <span>{{ vendorExtra.isFoundInCmp ? $t('Found in') : $t('global.filters.out') }} {{ $t('global.table.cmp') }}</span>
      </ListItem>

      <ListItem>
        <i class="fas fa-globe"
           style="margin-right: 5px"
        />
        <span> <strong>{{ vendorExtra.nbRequests > 1 ? $t('Identified requests') : $t('global.vendor.requests') }} : {{ vendorExtra.nbRequests }} - ({{ percentRequests(vendorExtra) }})</strong></span>
      </ListItem>
    </List>
    <Divider />
    <Row style="width:100%">
      <br>
      <p>List of trackers dropped by vendor</p>
      <br>
      <br>
      <Col span="24"
           v-if="vendorExtra.cookies.length"
      >
        <Collapse>
          <Panel :name="JSON.stringify(elm)"
                 v-for="elm of 3"
                 :key="elm"
          >
            <span :style="customStyle(titleScenarioArray[elm -1], filteredByScenario(titleScenarioArray[elm -1]).length)">{{ titleScenarioArray[elm -1] }} ({{ filteredByScenario(titleScenarioArray[elm -1]).length === 0 ? 'None' : filteredByScenario(titleScenarioArray[elm -1]).length }})</span>
            <Row slot="content"
                 type="flex"
                 justify="space-between"
            >
              <Col>
                <p style="margin-bottom:20px; height:inherit!important">
                  <strong>{{ $t('global.vendor.cookies_drop') }} : </strong>
                </p>

                <p style="height:inherit!important; margin-bottom:25px"
                   v-for="(val, key) of filteredByScenario(titleScenarioArray[elm -1])"
                   :key="key"
                >
                  {{ ellipsis(val.name) }}
                </p>
              </Col>
              <Col>
                <p style="margin-bottom:10px; height:inherit!important">
                  <strong>{{ $t('Exemption') }} : </strong>
                </p>
                
                <p style="height:inherit!important;margin-bottom:15px"
                   v-for="(val, key) of filteredByScenario(titleScenarioArray[elm -1])"
                   :key="key"
                >
                  <Tag color="blue"
                       v-if="val.is_exempted"
                  >
                    Exempted
                  </Tag>
                </p>
              </Col>
              <Col>
                <p style="margin-bottom:10px;height:inherit!important">
                  <strong>{{ $t('global.table.lifetime') }} :</strong>
                </p>
                <p style="height:inherit!important;margin-bottom:25px"
                   v-for="(val, key) of filteredByScenario(titleScenarioArray[elm -1])"
                   :key="key"
                >
                  {{ val.median_lifetime_days }} {{ $t('global.time.days') }}
                </p>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </Col>
      <p v-else>
        No cookies dropped
      </p>
      <Divider />
      <Col span="24">
        <p style="height:inherit!important">
          Vendors chaining
        </p>
        <br> <br>
        <Collapse 
          style="height:inherit!important"
        >
          <Panel :name="JSON.stringify(elm) + 'b'"
                 v-for="elm of 2"
                 :hide-arrow="elm === 1 ? !initiatorVendors.length : !activatedVendors.length"
                 :key="elm + 'b'"
          >
            <p style="margin-bottom:10px;height:inherit!important">
              {{ titleChainArray[elm -1] }} ({{ (elm === 1 ? initiatorVendors.length : activatedVendors.length) }}) {{ (elm === 1 ? initiatorVendors.length : activatedVendors.length) > 1 ? 'vendors' : 'vendor' }}
            </p>
            <Row style="width:100%"
                 slot="content"
                 type="flex"
                 justify="space-between"
            >
              <Col style="display:flex; justify-content: space-between; flex-wrap: wrap; max-height:300px; overflow-y: auto; width:100%">
                <span style="height:inherit!important;margin-bottom:25px;display:block;width:40%"
                      v-for="(val, key) of (elm === 1 ? initiatorVendors : activatedVendors)"
                      :key="key"
                >
                  <a v-if="(elm === 1 ? vendorTree.find(elm => elm.vendorName === val.initiatorVendorName).vendorUuid : val.vendorUuid)"
                     :href="'/#/vendors/' + (elm === 1 ? vendorTree.find(elm => elm.vendorName === val.initiatorVendorName).vendorUuid : val.vendorUuid)"
                  >{{ elm === 1 ? val.initiatorVendorName : val.vendorName }}</a>
                  <span v-else>{{ elm === 1 ? (val.initiatorVendorName || val.initiatorSld) : val.sld }}</span>
                </span>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </Col>
    </Row>
  </Card>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import SetupVendorModal from '../Modals/SetupVendorModal'

export default {
  nme: 'VendorActivity',
  components: { SetupVendorModal },
  props: {
    vendor: {
      type: Object,
      required: true,
      note: 'Vendor data sent by viewByName endpoint'
    }
  },
  data () {
    return {
      vendorExtra: null,
      vendorData: this.vendor,
      vendorTree: [],
      totalRequests: 0,
      riskColor: {
        A: '#6eba3e',
        B: '#25c882',
        C: '#035ac6',
        D: '#7e27dc',
        E: '#d7215f'
      },
      titleScenarioArray: [
        this.$t('global.scenarios.refusal_choice'),
        this.$t('global.scenarios.without_choice'),
        this.$t('global.scenarios.with_choice')
      ],
      titleChainArray: [
        'Requested by',
        'Initiated requests to '
      ],
      initiatorVendors: [],
      activatedVendors: []
    }
  },
  mounted () {
    if (this.scopeQs) {
      this.getTotalRequests()
      this.getVendorTree()
    }
   
    return this.getVendorByApi()
  },
  computed: {
    ...mapGetters({
      scopeQs: 'scope/qs',
      scopeSelectedProperty: 'scope/selectedProperty',
      userHasRole: 'user/hasRole',
      scopeSelectedDaterange: 'scope/selectedDaterange'
    }),
    whichStatus () {
      const surveillance = this.vendorExtra.property_vendordomain_rules.find(elm => elm.status === '1')
      if (surveillance) {
        return 'Watchlist'
      }
      return 'Whitelist'
    }
  },
  created() {
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {
        this.$emit('refresh')
      }
    )
  },
  watch: {
    scopeQs: function (val, old) {
      if (val !== old) {
        return this.getVendorByApi()
      }
    }
  },
  methods: {
    ...mapActions({
      vendorGetVendors: 'vendor/getVendors'
    }),
    customStyle (elm, value) {
      const checker = [
        this.$t('global.scenarios.refusal_choice'),
        this.$t('global.scenarios.without_choice')
      ]
      if (value && checker.includes(elm)) return `color: ${this.riskColor['E']}!important`
      return ''
      
    },
    percentRequests (vendorExtra) {
      if (((vendorExtra.nbRequests / this.totalRequests) * 100).toFixed(1) > 1) {
        return (((vendorExtra.nbRequests / this.totalRequests) * 100).toFixed(1) + '% of total requests done' ) 
      }
      else {
        return ('less than 1% of total requests done' ) 
      }
    },
    spaceNumber (nb) {
      return nb.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
    getTotalRequests () {
      let url = 'wrequests/requests02'
      url += `?properties[]=${this.scopeSelectedProperty.id}&startdate=${this.scopeSelectedDaterange[0]}&enddate=${this.scopeSelectedDaterange[1]}`
      return this.$axios.get(url).then(r => {
        const data = r.data.widget.value.data[0]
        const nbReqhest = data[2]
        const percent = parseFloat(data[3].replace('%', ''))
        this.totalRequests = (100*nbReqhest) / percent
      })
    },
    getVendorTree () {
      let url = 'wvendors/vendors34'
      url += `?properties[]=${this.scopeSelectedProperty.id}&startdate=${this.scopeSelectedDaterange[0]}&enddate=${this.scopeSelectedDaterange[1]}`
      return this.$axios.get(url).then(r => {
        const data = JSON.parse(r.data.widget.value)[0]
        this.vendorTree = data
        const whoRequestMe = data.filter(elm => elm.vendorId === this.vendorData.id)
        
        this.activatedVendors = this.removeDuplicateByField(data.filter(elm => elm.initiatorVendorId === this.vendorData.id), 'vendorId')
        this.initiatorVendors = this.removeDuplicateByField(whoRequestMe, 'initiatorSld')       
      })
    },
    removeDuplicateByField (arr, field) {
      const cleanArr = []
      arr.forEach(elm => {
        const exsiting = cleanArr.find(entity => entity[field] === elm[field])
        if (!exsiting) {
          cleanArr.push(elm)
        }
        // if (!cleanArr.find(entity => entity[field] === elm[field])) cleanArr.push(elm)
      })
      return cleanArr
    },
    filteredByScenario(scenario) {
      const cookies = this.vendorExtra.cookies
      if (scenario === this.$t('global.scenarios.without_choice')){ 
        return cookies.filter(elm => elm.cmp_scenario_id === "NO_USER_CHOICE")
      }
      if (scenario === this.$t('global.scenarios.refusal_choice')){ 
        return cookies.filter(elm => elm.cmp_scenario_id === "REFUSE_TO_ALL")
      }
      if (scenario === this.$t('global.scenarios.with_choice')){ 
        return cookies.filter(elm => elm.cmp_scenario_id === "CONSENT_TO_ALL")
      }
      return []
    },
    handleFormSubmit (payload) {
      this.getVendorByApi()
    },
    toogleEditModal () {
      this.$refs['vendorRulesModal'].openModal(this.vendorExtra)
    },
    getVendorByApi () {
      const params = {
        properties: [this.scopeSelectedProperty.id],
        period: this.scopeSelectedDaterange,
        id: this.vendor.id
      }
      if (!this.scopeQs) return false
      return this.vendorGetVendors({params, uid: this._uid})
        .then(resp => {
          this.vendorExtra = resp.data[0]
        })
    },
    ellipsis (string) {
      if (string.length > 15) {
        return string.substring(0, 12) + '...'
      }
      return string
    },
    vigilance (status) {
      if (status < 0.2) {
        return 'E'
      } else if (status < 0.4) {
        return 'D'
      } else if (status < 0.6) {
        return 'C'
      } else if (status < 0.8) {
        return 'B'
      }
      return 'A'
    },
    Lvigilance (status) {
      if (status < 0.2) {
        return 'Absolue'
      } else if (status < 0.4) {
        return 'Extreme'
      } else if (status < 0.6) {
        return 'Élevée'
      } else if (status < 0.8) {
        return 'Modérée'
      }
      return 'Faible'
    }
  }
}
</script>
<style lang="scss">

</style>