<template>
  <Card v-if="!isLoading">
    <template slot="title">
      <i
        class="fas fa-check-circle cmp-icon-ok"
        v-if="vendor.iabv2Id"
      />
      <i
        class="fas fa-times-circle cmp-icon-not-ok"
        v-else
      />
      {{ (vendor.iabv2Id && vendor.iabv2Id > 0) ? $t('Vendor\'s declaration in TCF') : $t('Vendor not declared in TCF') }}
    </template>
    <p
      slot="extra"
      v-if="vendor.iabv2Id && vendor.iabv2Id > 0"
    >
      ID #{{ vendor.iabv2Id }}
    </p>
    <div
      class="tcf-container"
      v-if="tcfData"
    >
      <div class="ivu-table ivu-table-default">
        <div class="ivu-table-tip">
          <table>
            <colgroup>
              <col
                span="1"
                style="width: 10%;"
              >
              <col
                span="1"
                style="width: 50%;"
              >
              <col
                span="1"
                style="width: 10%;"
              >
              <col
                span="1"
                style="width: 10%;"
              >
              <col
                span="1"
                style="width: 10%;"
              >
              <col
                span="1"
                style="width: 10%;"
              >
            </colgroup>
            <thead>
              <tr>
                <th
                  scope="col"
                  colspan="2"
                />
                <th scope="col">
                  {{ $t('global.vendor.consent') }}
                </th>
                <th scope="col">
                  {{ $t('global.vendor.flexible') }}
                </th>
                <th scope="col">
                  {{ $t('global.vendor.legit_interest') }}
                </th>
                <th scope="col">
                  {{ $t('global.vendor.info') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(sectionEntries, sectionName) of purposesAndFeatures">
                <tr
                  :key="sectionName"
                  scope="rowgroup"
                >
                  <th colspan="6">
                    {{ sectionName }}
                  </th>
                </tr>
                <template v-for="(sectionEntry,idx) of sectionEntries">
                  <tr :key="sectionName + idx">
                    <td class="id">
                      {{ sectionEntry.id }}
                    </td>
                    <td style="text-align:left">
                      {{ sectionEntry.name }}
                    </td>
                    <td>
                      <i
                        v-if="sectionEntry.legalBases.c"
                        class="fas fa-check"
                      />
                    </td>
                    <td>
                      <i
                        v-if="sectionEntry.legalBases.f"
                        class="fas fa-check"
                      />
                    </td>
                    <td>
                      <i
                        v-if="sectionEntry.legalBases.l"
                        class="fas fa-check"
                      />
                    </td>
                    <td>
                      <i
                        v-if="sectionEntry.legalBases.i"
                        class="fas fa-check"
                      />
                    </td>
                  <!--                  <td><i v-if="sectionEntry.legalBases.c" class="fas fa-check">{{sectionEntry.legalBases.c}}</i></td>-->
                  <!--                  <td><i v-if="sectionEntry.legalBases.f" class="fas fa-check">{{sectionEntry.legalBases.f}}</i></td>-->
                  <!--                  <td><i v-if="sectionEntry.legalBases.l" class="fas fa-check">{{sectionEntry.legalBases.l}}</i></td>-->
                  <!--                  <td><i v-if="sectionEntry.legalBases.i" class="fas fa-check">{{sectionEntry.legalBases.i}}</i></td>-->
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Card>
</template>
<script>
export default {
  name: 'VendorPurposes',
  props: {
    vendor: {
      type: Object,
      required: true,
      note: 'Vendor data'
    }
  },
  data () {
    return {
      tcfData: null,
      isLoading: false,
      vendorData: this.vendor,
      purposesAndFeatures: {
        'purposes': [
          {
            id: 1,
            vigilance: 3,
            legalBases: {
              'c': null
            },
            name: 'Store and/or access information on a device',
            description: 'Cookies, device identifiers, or other information can be stored or accessed on your device for the purposes presented to you.'
          },
          {
            id: 2,
            vigilance: 2,
            legalBases: {
              'c': null,
              'f': null,
              'l': null
            },
            name: 'Select basic ads',
            description: 'Ads can be shown to you based on the content you’re viewing, the app you’re using, your approximate location, or your device type.'
          }, {
            id: 3,
            vigilance: 3,
            legalBases: {
              'c': null,
              'f': null,
              'l': null
            },
            name: 'Create a personalised ads profile',
            description: 'A profile can be built about you and your interests to show you personalised ads that are relevant to you.'
          }, {
            id: 4,
            vigilance: 3,
            legalBases: {
              'c': null,
              'f': null,
              'l': null
            },
            name: 'Select personalised ads',
            description: 'Personalised ads can be shown to you based on a profile about you.'
          }, {
            id: 5,
            vigilance: 3,
            legalBases: {
              'c': null,
              'f': null,
              'l': null
            },
            name: 'Create a personalised content profile',
            description: 'Ads can be shown to you based on the content you’re viewing, the app you’re using, your approximate location, or your device type.'
          }, {
            id: 6,
            vigilance: 3,
            legalBases: {
              'c': null,
              'f': null,
              'l': null
            },
            name: 'Select personalised content',
            description: 'Personalised content can be shown to you based on a profile about you.'
          }, {
            id: 7,
            vigilance: 1,
            legalBases: {
              'c': null,
              'f': null,
              'l': null
            },
            name: 'Measure ad performance',
            description: 'The performance and effectiveness of ads that you see or interact with can be measured.'
          }, {
            id: 8,
            vigilance: 1,
            legalBases: {
              'c': null,
              'f': null,
              'l': null
            },
            name: 'Measure content performance',
            description: 'The performance and effectiveness of content that you see or interact with can be measured.'
          }, {
            id: 9,
            vigilance: 2,
            legalBases: {
              'c': null,
              'f': null,
              'l': null
            },
            name: 'Apply market research to generate audience insights',
            description: 'Market research can be used to learn more about the audiences who visit sites/apps and view ads.'
          }, {
            id: 10,
            vigilance: 2,
            legalBases: {
              'c': null,
              'f': null,
              'l': null
            },
            name: 'Develop and improve products',
            description: 'Your data can be used to improve existing systems and software, and to develop new products'
          }
        ],
        'specialPurposes': [
          {
            id: 1,
            vigilance: 1,
            legalBases: {
              'l': null
            },
            name: 'Ensure security, prevent fraud, and debug',
            description: 'Your data can be used to monitor for and prevent fraudulent activity, and ensure systems and processes work properly and securely.'
          }, {
            id: 2,
            vigilance: 2,
            legalBases: {
              'l': null
            },
            name: 'Technically deliver ads or content',
            description: 'Your device can receive and send information that allows you to see and interact with ads and content.'
          }
        ],
        'features': [
          {
            id: 1,
            vigilance: 5,
            legalBases: {
              'i': null
            },
            name: 'Match and combine offline data sources',
            description: 'Data from offline data sources can be combined with your online activity in support of one or more purposes'
          }, {
            id: 2,
            vigilance: 4,
            legalBases: {
              'i': null
            },
            name: 'Link different devices',
            description: 'Different devices can be determined as belonging to you or your household in support of one or more of purposes.'
          }, {
            id: 3,
            vigilance: 4,
            legalBases: {
              'i': null
            },
            name: 'Receive and use automatically-sent device characteristics for identification',
            description: 'Ads can be shown to you based on the content you’re viewing, the app you’re using, your approximate location, or your device type.'
          }
        ],
        'specialFeatures': [
          {
            id: 1,
            vigilance: 5,
            legalBases: {
              'c': null
            },
            name: 'Use precise geolocation data',
            description: 'Your precise geolocation data can be used in support of one or more purposes. This means your location can be accurate to within several meters.'
          }, {
            id: 2,
            vigilance: 4,
            legalBases: {
              'c': null
            },
            name: 'Actively scan device characteristics for identification',
            description: 'Your device can be identified based on a scan of your device\'s unique combination of characteristics.'
          }
        ]
      },
      tcfLegalBases: {
        'purposes': {
          target: 'purposes',
          legalBase: 'c'
        },
        'flexiblePurposes': {
          target: 'purposes',
          legalBase: 'f'
        },
        'legIntPurposes': {
          target: 'purposes',
          legalBase: 'l'
        },
        'specialPurposes': {
          target: 'specialPurposes',
          legalBase: 'l'
        },
        'features': {
          target: 'features',
          legalBase: 'i'
        },
        'specialFeatures': {
          target: 'specialFeatures',
          legalBase: 'c'
        }
      },
      riskColors: {
        A: '#6eba3e',
        B: '#25c882',
        C: '#035ac6',
        D: '#7e27dc',
        E: '#d7215f'
      }
    }
  },
  mounted () {
    return this.getTcfData().then(resp => {
      // fill purposeAndFeatures
      // console.log(this.tcfData)
      if (resp === false) return false
      for (const [key, val] of Object.entries(this.tcfData)) {
        // console.log(val)
        if (key in this.tcfLegalBases) { // if a definition exists
          const tcfLegalBaseEntry = this.tcfLegalBases[key] // get definition
          for (const tcfDataEntry of val) {
            // console.log(tcfDataEntry)
            let purposesAndFeaturesEntry = this.purposesAndFeatures[tcfLegalBaseEntry.target].find(
              el => (
                tcfLegalBaseEntry.legalBase in el.legalBases &&
                el.id === tcfDataEntry.id
              )
            )
            // TODO : set a warn level ?
            if (typeof purposesAndFeaturesEntry !== 'undefined') {
              // purposesAndFeaturesEntry.legalBases[tcfLegalBaseEntry.legalBase] = ''
              purposesAndFeaturesEntry.legalBases[tcfLegalBaseEntry.legalBase] = String.fromCharCode(parseInt(purposesAndFeaturesEntry.vigilance) + 64)
            }

            // console.log('plip', this.purposesAndFeatures[tcfLegalBaseEntry.target])
            // console.log('plop', tcfLegalBaseEntry.legalBase, tcfDataEntry.id)
            // console.log('dsfsdf', this.purposesAndFeatures[tcfLegalBaseEntry.target].find(
            //   el => (
            //     tcfLegalBaseEntry.legalBase in el.legalBases &&
            //     el.id === tcfDataEntry.id
            //   )
            // ))
            // console.log('hop', purposesAndFeaturesEntry)
          }
        }
      }
    })
  },
  computed: {},
  methods: {
    vigilanceColor ($letter) {
      return ($letter)
    },
    async getTcfData () {
      // this.isLoading = true
      if (this.vendorData.iabv2Id === null || this.vendorData.iabv2Id === 0) {
        return false
      } else {
        try {
          const {data: responseData} = await this.$axios.get('iab/getAllInformation?iabVendorId=' + this.vendorData.iabv2Id)
          if (responseData.success) {
            this.tcfData = responseData.results
            return responseData.results
          } else {
            this.$Message.warning('Error fetching API')
            this.tcfData = responseData.results
            return responseData.results
          }
        } catch (e) {
          throw new Error(e)
        }
        // return this.tcfData
      }
      // this.isLoading = false
    }

  }
}
</script>
<style scope lang="scss">
</style>
