<template lang="html">
  <div
    class="container-vendor"
    v-if="!isLoading"
  >
    <div class="header">
      <!--
      <div v-if="userHasRole('super-admin')" style="float:right">
        <Button type="primary"
                class="btn btn--elevated"
                :to="{path: '/admin/vendors/view', query: {vendorId: vendor.uuid}}">
          Manage
        </Button>
      </div>
      -->
      <!--Aliases-->
      <span
        class="chips chips--block-item chips--dark"
        v-for="(alias) of vendor.vendoraliases"
        :key="alias.id"
      >{{ alias.name }}</span>
      <br><br>
    </div>

    <Card>
      <h1>
        {{ vendor.name }}
        <Tag style="float-right;font-weight:700"
             v-if="vendor.is_exempted"
             color="blue"
        >
          Exempted
        </Tag>
      </h1>

      <!--            <Card v-if="vendor" :style="{'&#45;&#45;color': riskColor[vigilanceToLetter(gradeVendorVigilance())]}">-->
      <img
        :src="getLogosUrl"
        class="logo-vendor"
      >
      <br>
      <br>

      <div class="content">
        <!--      <strong>Description</strong>-->
        <!--      <p>{{ vendor.foundedAt ? 'Created on ' + vendor.foundedAt : 'Foundation year ' + vendor.foundation_year }}</p>-->
        <div
          class="content-desc"
          v-html="vendor.description ? vendor.description.replaceAll(/\.\s/g, '.<br/><br/>') : '' "
        />
        <br>

        <dl>
          <dt>{{ $t('global.vendor.privacy') }}</dt>
          <dd>
            <a
              target="_blank"
              :href="vendor.privacyPolicyUrl"
            >{{ vendor.privacyPolicyUrl }}</a>
          </dd>

          <dt>{{ $t('global.vendor.contact') }}</dt>
          <dd><a :href="`mailto:${vendor.websiteContactEmail}`">{{ vendor.websiteContactEmail }}</a></dd>

          <dt>{{ $t('global.vendor.contact_l') }}</dt>
          <dd><a :href="`mailto:${vendor.email}`">{{ vendor.email }}</a></dd>

          <dt>{{ $t('global.graph.website') }}</dt>
          <dd v-if="vendor.website">
            <a
              target="_blank"
              :href="fixExternalRedirect(vendor.website)"
            >{{ vendor.website }}</a>
          </dd>

          <dt>{{ $t('global.table.country') }}</dt>
          <dd>{{ vendor.country_code }}</dd>

          <dt>{{ $t('global.vendor.founded') }}</dt>
          <dd>{{ vendor.foundedAt }}</dd>
        </dl>
      </div>
    </Card>

    <br>
    <Row
      v-if="userHasRole('super-admin')"
      align="top"
    >
      <Col
        :lg="24"
        :md="24"
        :xs="24"
        :sm="24"
      >
        <Button
          type="primary"
          v-if="!showEditForm"
          @click="showEditForm = !showEditForm"
        >
          {{ $t('global.vendor.update') }}
        </Button>
        <Card v-else>
          <template slot="title">
            {{ $t('global.vendor.update') }} <span style="text-transform: lowercase;">({{ $t('global.vendor.last_u') }} : {{ moment(vendor.lastUpdateAt).format('LLL') }})</span>
          </template>
          <VendorEditor
            :input-vendor="vendor"
            @refresh="getVendorByUuid($route.params.uuid)"
          />
        </Card>
      </Col>
    </Row>

    <br>
    <Row
      :gutter="24"
      align="top"
    >
      <Col
        :lg="10"
        :md="24"
        :xs="24"
        :sm="24"
      >
        <Card v-if="!isLoading">
          <!--  vigilance generale nature-->
          <template slot="title">
            <strong>
              {{ $t('global.vendor.trust_i') }} :
              <span :style="{color: riskColor[vendorVigilanceToLetter(gradeVendorVigilance())]}"> {{ vendorVigilanceToLetter(gradeVendorVigilance()) }}</span>
            </strong>
          </template>
          <ul>
            <!--Vigilance pays-->
            <li
              class="vigilance"
              :style="{'--risk': riskColor[vendorVigilanceToLetter(vendor.country.vigilance_id)]}"
            >
              {{ vendor.location || 'Undefined localisation' }}
            </li>
            <!--Vigilance categorie-->
            <li
              class="vigilance"
              :style="{'--risk': riskColor[vendorVigilanceToLetter(vendor.category.vigilance_id)]}"
            >
              {{ vendor.category.name || 'N/A' }}
            </li>
            <!--Vigilance tcf-->
            <li style="display:flex; align-items:flex-end">
              <i
                class="fas fa-check-circle cmp-icon-ok"
                v-if="vendor.iabv2Id"
              />
              <i
                class="fas fa-times-circle cmp-icon-not-ok"
                v-else
              />
              <span>{{ $t('global.table.tcf') }}: {{ vendor.iabv2Id ? $t('global.filters.in') : $t('global.filters.out') }} </span>
            </li>
            <li
              v-if="vendor.iabv2Id && !!vendor.iabvendors2 && vendor.iabvendors2.vigilance_rating"
              class="vigilance"
              :style="{'--risk': riskColor[vendorVigilanceToLetter(gradeTcfVigilance(vendor.iabvendors2.vigilance_rating))]}"
            >
              {{ $t('global.vendor.tcf_index') }} F&P
            </li>
            <li
              v-if="vendor.vendor_googleadtechproviders.length"
              class="vigilance"
              :style="{'--risk': riskColor['grey']}"
            >
              <template>{{ isCommonUsed ? $t('global.vendor.adp_common_used') : $t('global.vendor.adp') }}</template>
            </li>
          </ul>
        </Card>
        <br>
        <VendorActivity v-if="!$route.path.includes('admin')"
                        :vendor="vendor"
                        @refresh="reFetchPage"
        />
      </Col>
      <Col
        :lg="14"
        :md="24"
        :xs="24"
        :sm="24"
      >
        <VendorPurposes :vendor="vendor" />
      </Col>
    </Row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { api } from '@/config'
import VendorActivity from './VendorActivity'
import VendorPurposes from './VendorPurposes'
import moment from 'moment'
import VendorEditor from './VendorEditor'
// import vendorColumns from './vast/campaign/columnsMixin/vColumns'

export default {
  name: 'Vendor',
  components: {
    VendorActivity,
    VendorPurposes,
    VendorEditor
  },
  data () {
    return {
      moment: moment,
      showEditForm: false,
      isLoading: true,
      riskColor: {
        grey: '#616a6894',
        A: '#81C327',
        B: '#6BAAD8',
        C: '#FFBD41',
        D: '#FF7850',
        E: '#D55D78'
      },
      vendor: {}
    }
  },
  computed: {
    ...mapGetters({
      scopeQs: 'scope/qs',
      userHasRole: 'user/hasRole',
      scopeSelectedDaterange: 'scope/selectedDaterange',
      vendorVigilanceToLetter: 'vendor/vendorVigilanceToLetter'
    }),
    getLogosUrl () {
      if (this.vendor && this.vendor.logo) {
        return `${api.root}img/vendors_updated/${this.vendor.logo}.png`
      }
      return ''
    },
    isCommonUsed () {
      if (!this.vendor.vendor_googleadtechproviders) {
        return false
      }

      const {vendor_googleadtechproviders} = this.vendor

      if (vendor_googleadtechproviders.length === 0 && !vendor_googleadtechproviders[0].commonused) {
        return false
      }

      return vendor_googleadtechproviders[0].commonused
    }
  },
  watch: {
    scopeQs: function (val, old) {
      if (val !== old) {
        if (this.params.uuid) {
          return this.getVendorByUuid(this.params.uuid)
        } else {
          return this.getVendorByName(this.params.name)
        }
      }
    }
  },
  
  methods: {
    ...mapActions({
      vendorGetVendorById: 'vendor/getVendorById'
    }),
    reFetchPage () {
      this.isLoading = true
      this.params = this.$route.params
      return this.getVendorByUuid(this.params.uuid)
    },
    fixExternalRedirect (url) {
      if (!url) return ''
      if (url.match('://')) return url
      return '//' + url
    },
    googleAdtechProvider () {
      const gap = this.vendor.vendor_googleadtechprovider
      if (gap) {
        return 1
      }
      return 5
    },
    /**
     * grade is from 1-5
     */
    gradeVendorVigilance () {
      // let globalVigilance = 0
      // if (this.vendor.country.vigilance_id && parseFloat(this.vendor.country.vigilance_id) > 0) {
      //   globalVigilance += (parseFloat(this.vendor.country.vigilance_id) * 0.3)
      // } else {
      //   globalVigilance += (3 * 0.3)
      // }
      // if (this.vendor.category.vigilance_id && parseFloat(this.vendor.category.vigilance_id) > 0) {
      //   globalVigilance += (parseFloat(this.vendor.category.vigilance_id) * 0.1)
      // } else {
      //   globalVigilance += (3 * 0.1)
      // }
      // if (this.vendor.iabvendors2 && this.vendor.iabvendors2.vigilance_rating && parseFloat(this.vendor.country.vigilance_id) > 0) {
      //   globalVigilance += (parseFloat(this.gradeTcfVigilance(this.vendor.iabvendors2.vigilance_rating) * 0.6))
      // } else {
      //   globalVigilance += (4 * 0.6)
      // }
      // return (globalVigilance)
      return this.vendor.vigilance_rating * 5
    },

    gradeTcfVigilance (rating) {
      rating = parseFloat(rating)
      if (rating < 16) {
        return 1
      } else if (rating < 26) {
        return 2
      } else if (rating < 36) {
        return 3
      } else if (rating < 46) {
        return 4
      } else {
        return 5
      }
    },
    ellipsis (string = '') {
      if (string && string.length > 15) {
        return string.substring(0, 12) + '...'
      }
      return string
    },
    getVendorByUuid (vendorUuid) {
      return this.vendorGetVendorById(vendorUuid)
        .then(response => {
          this.isLoading = false
          this.vendor = response.data
          this.vendor.is_exempted = !!this.vendor.is_exempted
          if (!this.vendor.country) this.vendor.country = {}
          if (!this.vendor.category) this.vendor.category = {}
        })
    },
    vigilance (status) {
      if (status < 0.2) {
        return 'E'
      } else if (status < 0.4) {
        return 'D'
      } else if (status < 0.6) {
        return 'C'
      } else if (status < 0.8) {
        return 'B'
      }
      return 'A'
    }
  },
  mounted () {
    this.params = this.$route.params
    return this.getVendorByUuid(this.params.uuid)
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/variables";

  p, span {
    color: #495060
  }

  a span {
    color: #fff
  }

  button span {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
  }

  .logo-vendor {
    // position: absolute;
    float: right;
    // z-index: 2;
    width: 160px;
    margin-left: 16px;

  }

  .cmp-icon-ok {
    color: #6eba3e
  }

  .cmp-icon-not-ok {
    color: #d7215f
  }

  i {
    padding-right: 5px;
    font-size: 20px;
    vertical-align: text-top;
  }

  .tooltip {
    padding: 10px 10px;
    background: #fff;
    margin: 7px 0;
    width: 100%;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);

    &:hover {

      box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.2);
    }
  }

  .ariane {
    span {
      font-weight: 600;
      color: #333;
      font-size: 16px;

      &:first-child {
        cursor: pointer
      }

      &:last-child {
        text-decoration: underline;
      }
    }
  }

  .tcf-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .boxes {
      width: calc(33.3% - 12px);
      margin-bottom: 24px;
      border: 1px solid #dddee1;
      border-radius: 4px;
      background: #EDF2F1;
      box-sizing: border-box;
      padding: 12px;

      p {
        margin: 5px 0;
        display: block;
        cursor: pointer;

        div:hover {
          color: #000
        }
      }
    }
  }

  .card {
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
    border-radius: 0, 35rem !important;
    margin-bottom: 24px;

  }

  ul li {
    margin: 10px 0;
  }

  .vigilance {
    position: relative;
    --risk: #fff;
    padding-left: 28px;

    &::before {
      content: '';
      width: 20px;
      height: 20px;
      display: block;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background: var(--risk);
    }
  }

  section {

    --color: #fff;
    --color2: #a8e06303;

    &:first-child {
      &::before {
        content: '';
        display: block;
        position: absolute;
        height: 60px;
        width: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(var(--color), var(--color2))
      }
    }

    .vendor-header {
      margin-top: 55px;

      p {
        font-size: 28px;
        font-weight: 500;
      }

      img {
        max-width: 150px;
        width: 100%;
      }

      display: flex;
      justify-content: space-between;
    }

    button {
      a {
        font-size: 16px;
        font-weight: 400;
        color: #fff;
      }
    }

    .location, .footer {
      margin: 30px 0;
    }

  }

  .header {
    margin: 15px 0;
    display: block;
    width: 100%;
  }

</style>
