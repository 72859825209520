var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.isLoading ? _c('div', {
    staticClass: "container-vendor"
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._l(_vm.vendor.vendoraliases, function (alias) {
    return _c('span', {
      key: alias.id,
      staticClass: "chips chips--block-item chips--dark"
    }, [_vm._v(_vm._s(alias.name))]);
  }), _c('br'), _c('br')], 2), _c('Card', [_c('h1', [_vm._v(" " + _vm._s(_vm.vendor.name) + " "), _vm.vendor.is_exempted ? _c('Tag', {
    staticStyle: {
      "font-weight": "700"
    },
    attrs: {
      "color": "blue"
    }
  }, [_vm._v(" Exempted ")]) : _vm._e()], 1), _c('img', {
    staticClass: "logo-vendor",
    attrs: {
      "src": _vm.getLogosUrl
    }
  }), _c('br'), _c('br'), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "content-desc",
    domProps: {
      "innerHTML": _vm._s(_vm.vendor.description ? _vm.vendor.description.replaceAll(/\.\s/g, '.<br/><br/>') : '')
    }
  }), _c('br'), _c('dl', [_c('dt', [_vm._v(_vm._s(_vm.$t('global.vendor.privacy')))]), _c('dd', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": _vm.vendor.privacyPolicyUrl
    }
  }, [_vm._v(_vm._s(_vm.vendor.privacyPolicyUrl))])]), _c('dt', [_vm._v(_vm._s(_vm.$t('global.vendor.contact')))]), _c('dd', [_c('a', {
    attrs: {
      "href": "mailto:".concat(_vm.vendor.websiteContactEmail)
    }
  }, [_vm._v(_vm._s(_vm.vendor.websiteContactEmail))])]), _c('dt', [_vm._v(_vm._s(_vm.$t('global.vendor.contact_l')))]), _c('dd', [_c('a', {
    attrs: {
      "href": "mailto:".concat(_vm.vendor.email)
    }
  }, [_vm._v(_vm._s(_vm.vendor.email))])]), _c('dt', [_vm._v(_vm._s(_vm.$t('global.graph.website')))]), _vm.vendor.website ? _c('dd', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": _vm.fixExternalRedirect(_vm.vendor.website)
    }
  }, [_vm._v(_vm._s(_vm.vendor.website))])]) : _vm._e(), _c('dt', [_vm._v(_vm._s(_vm.$t('global.table.country')))]), _c('dd', [_vm._v(_vm._s(_vm.vendor.country_code))]), _c('dt', [_vm._v(_vm._s(_vm.$t('global.vendor.founded')))]), _c('dd', [_vm._v(_vm._s(_vm.vendor.foundedAt))])])])]), _c('br'), _vm.userHasRole('super-admin') ? _c('Row', {
    attrs: {
      "align": "top"
    }
  }, [_c('Col', {
    attrs: {
      "lg": 24,
      "md": 24,
      "xs": 24,
      "sm": 24
    }
  }, [!_vm.showEditForm ? _c('Button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.showEditForm = !_vm.showEditForm;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.vendor.update')) + " ")]) : _c('Card', [_c('template', {
    slot: "title"
  }, [_vm._v(" " + _vm._s(_vm.$t('global.vendor.update')) + " "), _c('span', {
    staticStyle: {
      "text-transform": "lowercase"
    }
  }, [_vm._v("(" + _vm._s(_vm.$t('global.vendor.last_u')) + " : " + _vm._s(_vm.moment(_vm.vendor.lastUpdateAt).format('LLL')) + ")")])]), _c('VendorEditor', {
    attrs: {
      "input-vendor": _vm.vendor
    },
    on: {
      "refresh": function refresh($event) {
        return _vm.getVendorByUuid(_vm.$route.params.uuid);
      }
    }
  })], 2)], 1)], 1) : _vm._e(), _c('br'), _c('Row', {
    attrs: {
      "gutter": 24,
      "align": "top"
    }
  }, [_c('Col', {
    attrs: {
      "lg": 10,
      "md": 24,
      "xs": 24,
      "sm": 24
    }
  }, [!_vm.isLoading ? _c('Card', [_c('template', {
    slot: "title"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.$t('global.vendor.trust_i')) + " : "), _c('span', {
    style: {
      color: _vm.riskColor[_vm.vendorVigilanceToLetter(_vm.gradeVendorVigilance())]
    }
  }, [_vm._v(" " + _vm._s(_vm.vendorVigilanceToLetter(_vm.gradeVendorVigilance())))])])]), _c('ul', [_c('li', {
    staticClass: "vigilance",
    style: {
      '--risk': _vm.riskColor[_vm.vendorVigilanceToLetter(_vm.vendor.country.vigilance_id)]
    }
  }, [_vm._v(" " + _vm._s(_vm.vendor.location || 'Undefined localisation') + " ")]), _c('li', {
    staticClass: "vigilance",
    style: {
      '--risk': _vm.riskColor[_vm.vendorVigilanceToLetter(_vm.vendor.category.vigilance_id)]
    }
  }, [_vm._v(" " + _vm._s(_vm.vendor.category.name || 'N/A') + " ")]), _c('li', {
    staticStyle: {
      "display": "flex",
      "align-items": "flex-end"
    }
  }, [_vm.vendor.iabv2Id ? _c('i', {
    staticClass: "fas fa-check-circle cmp-icon-ok"
  }) : _c('i', {
    staticClass: "fas fa-times-circle cmp-icon-not-ok"
  }), _c('span', [_vm._v(_vm._s(_vm.$t('global.table.tcf')) + ": " + _vm._s(_vm.vendor.iabv2Id ? _vm.$t('global.filters.in') : _vm.$t('global.filters.out')) + " ")])]), _vm.vendor.iabv2Id && !!_vm.vendor.iabvendors2 && _vm.vendor.iabvendors2.vigilance_rating ? _c('li', {
    staticClass: "vigilance",
    style: {
      '--risk': _vm.riskColor[_vm.vendorVigilanceToLetter(_vm.gradeTcfVigilance(_vm.vendor.iabvendors2.vigilance_rating))]
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.vendor.tcf_index')) + " F&P ")]) : _vm._e(), _vm.vendor.vendor_googleadtechproviders.length ? _c('li', {
    staticClass: "vigilance",
    style: {
      '--risk': _vm.riskColor['grey']
    }
  }, [[_vm._v(_vm._s(_vm.isCommonUsed ? _vm.$t('global.vendor.adp_common_used') : _vm.$t('global.vendor.adp')))]], 2) : _vm._e()])], 2) : _vm._e(), _c('br'), !_vm.$route.path.includes('admin') ? _c('VendorActivity', {
    attrs: {
      "vendor": _vm.vendor
    },
    on: {
      "refresh": _vm.reFetchPage
    }
  }) : _vm._e()], 1), _c('Col', {
    attrs: {
      "lg": 14,
      "md": 24,
      "xs": 24,
      "sm": 24
    }
  }, [_c('VendorPurposes', {
    attrs: {
      "vendor": _vm.vendor
    }
  })], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }