var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.vendorExtra && _vm.scopeSelectedDaterange.length > 0 && _vm.scopeQs ? _c('Card', {
    staticClass: "vendor-activity"
  }, [_c('template', {
    slot: "title"
  }, [_c('strong', [_vm._v(_vm._s(_vm.ellipsis(_vm.vendorExtra.name + ' ')) + " ")]), _vm._v(_vm._s(_vm.$t('global.vendor.activity')) + " ")]), _c('template', {
    slot: "extra"
  }, [_c('p', {
    staticStyle: {
      "border": "none!important",
      "margin-top": "4px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.vendor.collect_period')) + " "), _c('strong', [_vm._v(_vm._s(_vm.scopeSelectedDaterange[0]) + " - " + _vm._s(_vm.scopeSelectedDaterange[1]) + " ")])])]), _c('p', {
    staticStyle: {
      "margin-top": "20px",
      "display": "flex",
      "justify-content": "space-between",
      "align-items": "baseline"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('Status')) + " : " + _vm._s(_vm.whichStatus))]), !_vm.userHasRole('vendors-viewer') && !_vm.userHasRole('publisher-fp') ? _c('Button', {
    staticStyle: {
      "margin": "0 10px",
      "text-align": "right"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.toogleEditModal
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.table.qualify')) + " ")]) : _vm._e()], 1), _c('SetupVendorModal', {
    ref: "vendorRulesModal",
    on: {
      "on-form-submit-success": _vm.handleFormSubmit
    }
  }), _c('Divider'), _c('List', [_c('ListItem', [_vm.vendorExtra.adstxtvendors.length > 0 ? _c('i', {
    staticClass: "fas fa-check-circle cmp-icon-ok",
    staticStyle: {
      "margin-right": "5px"
    }
  }) : _c('i', {
    staticClass: "fas fa-times-circle cmp-icon-not-ok",
    staticStyle: {
      "margin-right": "5px"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.vendorExtra.adstxtvendors.length > 0 ? _vm.$t('Found in') : _vm.$t('global.filters.out')) + " " + _vm._s(_vm.$t('Ads.txt')))])]), _c('ListItem', [_vm.vendorExtra.isFoundInCmp ? _c('i', {
    staticClass: "fas fa-check-circle cmp-icon-ok",
    staticStyle: {
      "margin-right": "5px"
    }
  }) : _c('i', {
    staticClass: "fas fa-times-circle cmp-icon-not-ok",
    staticStyle: {
      "margin-right": "5px"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.vendorExtra.isFoundInCmp ? _vm.$t('Found in') : _vm.$t('global.filters.out')) + " " + _vm._s(_vm.$t('global.table.cmp')))])]), _c('ListItem', [_c('i', {
    staticClass: "fas fa-globe",
    staticStyle: {
      "margin-right": "5px"
    }
  }), _c('span', [_c('strong', [_vm._v(_vm._s(_vm.vendorExtra.nbRequests > 1 ? _vm.$t('Identified requests') : _vm.$t('global.vendor.requests')) + " : " + _vm._s(_vm.vendorExtra.nbRequests) + " - (" + _vm._s(_vm.percentRequests(_vm.vendorExtra)) + ")")])])])], 1), _c('Divider'), _c('Row', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('br'), _c('p', [_vm._v("List of trackers dropped by vendor")]), _c('br'), _c('br'), _vm.vendorExtra.cookies.length ? _c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('Collapse', _vm._l(3, function (elm) {
    return _c('Panel', {
      key: elm,
      attrs: {
        "name": JSON.stringify(elm)
      }
    }, [_c('span', {
      style: _vm.customStyle(_vm.titleScenarioArray[elm - 1], _vm.filteredByScenario(_vm.titleScenarioArray[elm - 1]).length)
    }, [_vm._v(_vm._s(_vm.titleScenarioArray[elm - 1]) + " (" + _vm._s(_vm.filteredByScenario(_vm.titleScenarioArray[elm - 1]).length === 0 ? 'None' : _vm.filteredByScenario(_vm.titleScenarioArray[elm - 1]).length) + ")")]), _c('Row', {
      attrs: {
        "slot": "content",
        "type": "flex",
        "justify": "space-between"
      },
      slot: "content"
    }, [_c('Col', [_c('p', {
      staticStyle: {
        "margin-bottom": "20px",
        "height": "inherit!important"
      }
    }, [_c('strong', [_vm._v(_vm._s(_vm.$t('global.vendor.cookies_drop')) + " : ")])]), _vm._l(_vm.filteredByScenario(_vm.titleScenarioArray[elm - 1]), function (val, key) {
      return _c('p', {
        key: key,
        staticStyle: {
          "height": "inherit!important",
          "margin-bottom": "25px"
        }
      }, [_vm._v(" " + _vm._s(_vm.ellipsis(val.name)) + " ")]);
    })], 2), _c('Col', [_c('p', {
      staticStyle: {
        "margin-bottom": "10px",
        "height": "inherit!important"
      }
    }, [_c('strong', [_vm._v(_vm._s(_vm.$t('Exemption')) + " : ")])]), _vm._l(_vm.filteredByScenario(_vm.titleScenarioArray[elm - 1]), function (val, key) {
      return _c('p', {
        key: key,
        staticStyle: {
          "height": "inherit!important",
          "margin-bottom": "15px"
        }
      }, [val.is_exempted ? _c('Tag', {
        attrs: {
          "color": "blue"
        }
      }, [_vm._v(" Exempted ")]) : _vm._e()], 1);
    })], 2), _c('Col', [_c('p', {
      staticStyle: {
        "margin-bottom": "10px",
        "height": "inherit!important"
      }
    }, [_c('strong', [_vm._v(_vm._s(_vm.$t('global.table.lifetime')) + " :")])]), _vm._l(_vm.filteredByScenario(_vm.titleScenarioArray[elm - 1]), function (val, key) {
      return _c('p', {
        key: key,
        staticStyle: {
          "height": "inherit!important",
          "margin-bottom": "25px"
        }
      }, [_vm._v(" " + _vm._s(val.median_lifetime_days) + " " + _vm._s(_vm.$t('global.time.days')) + " ")]);
    })], 2)], 1)], 1);
  }), 1)], 1) : _c('p', [_vm._v(" No cookies dropped ")]), _c('Divider'), _c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('p', {
    staticStyle: {
      "height": "inherit!important"
    }
  }, [_vm._v(" Vendors chaining ")]), _c('br'), _vm._v(" "), _c('br'), _c('Collapse', {
    staticStyle: {
      "height": "inherit!important"
    }
  }, _vm._l(2, function (elm) {
    return _c('Panel', {
      key: elm + 'b',
      attrs: {
        "name": JSON.stringify(elm) + 'b',
        "hide-arrow": elm === 1 ? !_vm.initiatorVendors.length : !_vm.activatedVendors.length
      }
    }, [_c('p', {
      staticStyle: {
        "margin-bottom": "10px",
        "height": "inherit!important"
      }
    }, [_vm._v(" " + _vm._s(_vm.titleChainArray[elm - 1]) + " (" + _vm._s(elm === 1 ? _vm.initiatorVendors.length : _vm.activatedVendors.length) + ") " + _vm._s((elm === 1 ? _vm.initiatorVendors.length : _vm.activatedVendors.length) > 1 ? 'vendors' : 'vendor') + " ")]), _c('Row', {
      staticStyle: {
        "width": "100%"
      },
      attrs: {
        "slot": "content",
        "type": "flex",
        "justify": "space-between"
      },
      slot: "content"
    }, [_c('Col', {
      staticStyle: {
        "display": "flex",
        "justify-content": "space-between",
        "flex-wrap": "wrap",
        "max-height": "300px",
        "overflow-y": "auto",
        "width": "100%"
      }
    }, _vm._l(elm === 1 ? _vm.initiatorVendors : _vm.activatedVendors, function (val, key) {
      return _c('span', {
        key: key,
        staticStyle: {
          "height": "inherit!important",
          "margin-bottom": "25px",
          "display": "block",
          "width": "40%"
        }
      }, [(elm === 1 ? _vm.vendorTree.find(function (elm) {
        return elm.vendorName === val.initiatorVendorName;
      }).vendorUuid : val.vendorUuid) ? _c('a', {
        attrs: {
          "href": '/#/vendors/' + (elm === 1 ? _vm.vendorTree.find(function (elm) {
            return elm.vendorName === val.initiatorVendorName;
          }).vendorUuid : val.vendorUuid)
        }
      }, [_vm._v(_vm._s(elm === 1 ? val.initiatorVendorName : val.vendorName))]) : _c('span', [_vm._v(_vm._s(elm === 1 ? val.initiatorVendorName || val.initiatorSld : val.sld))])]);
    }), 0)], 1)], 1);
  }), 1)], 1)], 1)], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }