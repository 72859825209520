var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.isLoading ? _c('Card', [_c('template', {
    slot: "title"
  }, [_vm.vendor.iabv2Id ? _c('i', {
    staticClass: "fas fa-check-circle cmp-icon-ok"
  }) : _c('i', {
    staticClass: "fas fa-times-circle cmp-icon-not-ok"
  }), _vm._v(" " + _vm._s(_vm.vendor.iabv2Id && _vm.vendor.iabv2Id > 0 ? _vm.$t('Vendor\'s declaration in TCF') : _vm.$t('Vendor not declared in TCF')) + " ")]), _vm.vendor.iabv2Id && _vm.vendor.iabv2Id > 0 ? _c('p', {
    attrs: {
      "slot": "extra"
    },
    slot: "extra"
  }, [_vm._v(" ID #" + _vm._s(_vm.vendor.iabv2Id) + " ")]) : _vm._e(), _vm.tcfData ? _c('div', {
    staticClass: "tcf-container"
  }, [_c('div', {
    staticClass: "ivu-table ivu-table-default"
  }, [_c('div', {
    staticClass: "ivu-table-tip"
  }, [_c('table', [_c('colgroup', [_c('col', {
    staticStyle: {
      "width": "10%"
    },
    attrs: {
      "span": "1"
    }
  }), _c('col', {
    staticStyle: {
      "width": "50%"
    },
    attrs: {
      "span": "1"
    }
  }), _c('col', {
    staticStyle: {
      "width": "10%"
    },
    attrs: {
      "span": "1"
    }
  }), _c('col', {
    staticStyle: {
      "width": "10%"
    },
    attrs: {
      "span": "1"
    }
  }), _c('col', {
    staticStyle: {
      "width": "10%"
    },
    attrs: {
      "span": "1"
    }
  }), _c('col', {
    staticStyle: {
      "width": "10%"
    },
    attrs: {
      "span": "1"
    }
  })]), _c('thead', [_c('tr', [_c('th', {
    attrs: {
      "scope": "col",
      "colspan": "2"
    }
  }), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.vendor.consent')) + " ")]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.vendor.flexible')) + " ")]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.vendor.legit_interest')) + " ")]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.vendor.info')) + " ")])])]), _c('tbody', [_vm._l(_vm.purposesAndFeatures, function (sectionEntries, sectionName) {
    return [_c('tr', {
      key: sectionName,
      attrs: {
        "scope": "rowgroup"
      }
    }, [_c('th', {
      attrs: {
        "colspan": "6"
      }
    }, [_vm._v(" " + _vm._s(sectionName) + " ")])]), _vm._l(sectionEntries, function (sectionEntry, idx) {
      return [_c('tr', {
        key: sectionName + idx
      }, [_c('td', {
        staticClass: "id"
      }, [_vm._v(" " + _vm._s(sectionEntry.id) + " ")]), _c('td', {
        staticStyle: {
          "text-align": "left"
        }
      }, [_vm._v(" " + _vm._s(sectionEntry.name) + " ")]), _c('td', [sectionEntry.legalBases.c ? _c('i', {
        staticClass: "fas fa-check"
      }) : _vm._e()]), _c('td', [sectionEntry.legalBases.f ? _c('i', {
        staticClass: "fas fa-check"
      }) : _vm._e()]), _c('td', [sectionEntry.legalBases.l ? _c('i', {
        staticClass: "fas fa-check"
      }) : _vm._e()]), _c('td', [sectionEntry.legalBases.i ? _c('i', {
        staticClass: "fas fa-check"
      }) : _vm._e()])])];
    })];
  })], 2)])])])]) : _vm._e()], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }